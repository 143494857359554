<template>
  <CBox>
    <CImage
      :src="require(`@/assets/icon/${icon}`)"
      :w="btnWidth"
      :h="btnHeight"
    />
  </CBox>
</template>

<script>
export default {
  name: "ButtonIcon",
  props: {
    icon: {
      type: String,
      default: "",
    },
    // add btn prefix to avoid bump with ChakraUI style props
    btnWidth: {
      type: String,
      default: "20px",
    },
    btnHeight: {
      type: String,
      default: "20px",
    },
  },
};
</script>
